import React, { useState } from "react"
import Layout from "../../chunks/layout"
import { Helmet } from "react-helmet"
import Main from "../../components/main"
import Pricing from "../../components/pricing"
import GuaranteeSection from "../../components/guaranteesection"
import Features from "../../components/features"
import Testimonials from "../../components/testimonials"
import Faqs from "../../components/faqs"
import HowItWorks from "../../components/howitworks"

const SoundCloud = props => {
  const [productName, setProductName] = useState(props.productName)
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Checkout | Booktik</title>
        <link rel="canonical" href="https://boostik.io/" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Main
        viewName="soundcloud"
        title="ACCELERATE YOUR GROWTH ON SOUNDCLOUD."
        title="Accelerate your growth on Soundcloud"
        subTitle=""
        description="With the help of an initial boost it’s much easier to grow your channel if you are new to the platform"
      />
      <div className="w-full bg-gray">
        <div className="background-gray-wave-separator-rotated"></div>
      </div>
      <div className="section-container bg-gray">
        <div className="section-content">
          <div className="flex justify-center items-center">
            <button
              className={`pricing-menu-button ${
                productName === "followers" &&
                `pricing-menu-button-selected color-soundcloud shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("followers")
              }}
              onKeyDown={() => {
                setProductName("followers")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-user text-3xl"></i>
              </div>
              Followers
            </button>
            <button
              className={`pricing-menu-button ${
                productName === "likes" &&
                `pricing-menu-button-selected color-soundcloud shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("likes")
              }}
              onKeyDown={() => {
                setProductName("likes")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-heart text-3xl"></i>
              </div>
              Likes
            </button>
            {/*<button
              className={`pricing-menu-button ${
                productName === "comments" &&
                `pricing-menu-button-selected color-soundcloud shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("comments")
              }}
              onKeyDown={() => {
                setProductName("comments")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-comment text-3xl"></i>
              </div>
              Comments
            </button>*/}
            <button
              className={`pricing-menu-button ${
                productName === "reposts" &&
                `pricing-menu-button-selected color-soundcloud shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("reposts")
              }}
              onKeyDown={() => {
                setProductName("reposts")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-repost text-3xl"></i>
              </div>
              Reposts
            </button>
            <button
              className={`pricing-menu-button ${
                productName === "plays" &&
                `pricing-menu-button-selected color-soundcloud shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("plays")
              }}
              onKeyDown={() => {
                setProductName("plays")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-caret-right text-3xl"></i>
              </div>
              Plays
            </button>
          </div>
        </div>
        <Pricing
          viewName="soundcloud"
          categoryName="soundcloud"
          productName={productName}
        />
      </div>
      <GuaranteeSection viewName="soundcloud" />
      <div className="section-container background-section-gray">
        <Features viewName="soundcloud" categoryName="soundcloud" />
      </div>
      <HowItWorks viewName="soundcloud" categoryName="soundcloud" />
      <div className="background-section-gray">
        <div className="container mx-auto text-center">
          <div className={`section-title color-soundcloud mt-10`}>
            Some Reviews and Customer Feedback
          </div>
          <div>
            <Testimonials
              categoryName="soundcloud"
              testimonialsList={[
                {
                  name: "Marla Cameron",
                  review: 4,
                  comment:
                    "“I recommend it without a doubt, it's super fast and you see the results immediately.”",
                  userImage: "user12",
                },
                {
                  name: "Michael Johnson",
                  review: 5,
                  comment:
                    "“I've bought plays for new tracks and it really gives me more visibility”.",
                  userImage: "user13",
                },
                {
                  name: "Tyler Brown",
                  review: 4.5,
                  comment:
                    "“In a nutshell, I bought plays on Soundcloud and the results blew me away, I didn't expect it to be so quick and easy”.",
                  userImage: "user14",
                },
                {
                  name: "John Davis",
                  review: 4.5,
                  comment:
                    "“It’s trustworthy, I purchased a pack of likes and followers, both were delivered so fast”.",
                  userImage: "user15",
                },
                {
                  name: "Liam Williams",
                  review: 4,
                  comment:
                    "“Great service, I have used it several times already to give my tracks a boost, it really works!”",
                  userImage: "user16",
                },
                {
                  name: "Christopher Rob",
                  review: 4,
                  comment:
                    "“Low-price and the service is legit, so I would def recommend it.”.",
                  userImage: "user17",
                },
                {
                  name: "Matty Smith",
                  review: 5,
                  comment:
                    "“On Soundcloud you know, the more the merrier, so I would recommend it!”.",
                  userImage: "user18",
                },
                {
                  name: "Sarah Dawson",
                  review: 5,
                  comment:
                    "“I thought I could reinforce my tracks with a little external impulse and I don't regret having done so!”",
                  userImage: "user20",
                },
                {
                  name: "Justin Milnes",
                  review: 4,
                  comment: "“It actually works, all of the likes arrived”",
                  userImage: "user21",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <Faqs
        listOfFaqs={[
          {
            title: "WHEN WILL MY ORDER START?",
            description:
              "The start time for all services can vary and is listed on the Service Description. Some services have an instant start time and others may have a start time that is delayed up to 24 hours. Please be sure you review the Service Description for the service you are interested in before placing the order. Please note that for YouTube services, YouTube doesn’t update all stats in real-time so it can take up to 6 hours for you to see the effects from your order.",
          },
          {
            title: "IS THIS SAFE FOR MY ACCOUNT?",
            description:
              "Yes, it’s 100% safe to use Boostik. All of our social media marketing services comply fully with the terms, conditions and guidelines of whichever platform we’re dealing with. There is absolutely no chance whatsoever that your account will be penalized in any way. We constantly monitor all of the major social media players for updates and adapt our services accordingly. This means that you will always be one step ahead and your account will never be in jeopardy.",
          },
          {
            title: "HOW FAST WILL I RECEIVE THE SERVICES?",
            description:
              "Services for Soundcloud are so fast. We can confidently assure you that in less than half an hour you will have started to see your stats increase.The longest you will ever have to wait for any service will be 24 hours. Please note that delivery times are estimated and may vary during busier periods. In case you need more information about our delivery times, just send us an email, and we’ll be glad to resolve your doubts.",
          },
          {
            title: "DO YOU NEED MY PASSWORD?",
            description:
              "It is not necessary to have your password in order to deliver a high-quality service. All we need is a link to the content you want to promote to get started. We do NOT require and will never ask for your password or account access. All we need is your Social Media URL/username and your email so that we can contact you regarding your order process. By doing so, we eliminate the possibility of your Social Media profile being breached by any third parties.",
          },
          {
            title: "WHICH PACKAGE IS RIGHT FOR ME?",
            description:
              "We have assigned a dedicated team of experts to constantly analyze Youtube. This makes it possible for us to always provide the 'Best Value' option for the most unbeatable price on the Internet. As for the right package for you, this will depend on your requirements. Remember that we are at your disposal in case you need further help.",
          },
          {
            title: "WHAT PAYMENT METHODS DO YOU ACCEPT?",
            description:
              "At the moment we accept payments through credit and debit cards.",
          },
          {
            title: "CAN I GET A REFUND?",
            description:
              "Your order is eligible for refund if it has not been completed yet. If we fail to deliver your order, you can request for a full refund. Please note different packages have different delivery periods.",
          },
          {
            title: "CAN I CANCEL MY ORDER?",
            description:
              "Once an order has been placed it is not always possible to cancel it. Many of our services have very fast delivery speeds, therefore making it impossible to cancel the order before it’s delivered. In the event that you need to cancel an order please contact our Customer Support Service and we will do our best to stop it as soon as possible. We do not issue refunds for services that have been delivered.",
          },
          {
            title: "MORE QUESTIONS?",
            description:
              "If you cannot find your answer here, you can send us an email. Our Customer Support Team will be happy to help you!",
          },
        ]}
        viewName="soundcloud"
      />
    </Layout>
  )
}

export default SoundCloud
